import React, { useRef } from 'react';
import { useLocation  } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, {
    Column,
    HeaderFilter,
    Lookup
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import { setUnactiveRows } from '../../common/utils';
import {
    LanguageTemplate,
    dataGridDefaultSettings,
} from '../../components';

const PayerPage = ({
    store: {
        languages: { table },
    },
}) => {
    const ref = useRef();

    return (
        <>
            <DataGrid
                ref={ref}
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_info_payer_USERID',
                    url: apiUrls.info.payer,
                    key: 'id',
                    language: languages.languageId[table],
                    languagePicker: false,
                    toolbarCenter: `${useLocation().pathname}`,
                    editing: {
                        allowAdding: false,
                        allowUpdating: true,
                        allowDeleting: false,
                    },
                })}
                onRowPrepared={setUnactiveRows}
            >
                <HeaderFilter visible={true} allowSearch={true} />

                <Column
                    dataField={'customerId'}
                    caption={trans('Клиент')}
                    dataType={'number'}
                    width={250}
                    allowHeaderFiltering={true}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.customer,
                            onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={90}
                    sortOrder={'asc'}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'serviceId'}
                    caption={trans('Сервис')}
                    dataType={'number'}
                    width={432}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.service,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'currencyId'}
                    caption={trans('Валюта')}
                    dataType={'number'}
                    width={100}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.currency,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'account'}
                    caption={trans('Аккаунт')}
                    dataType={'string'}
                    allowFixing={false}
                    width={278}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'balance'}
                    caption={trans('Баланс')}
                    dataType={'number'}
                    width={245}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'idt'}
                    caption={trans('Дата вставки')}
                    dataType={'datetime'}
                    format={'dd/MM/yyyy HH:mm:ss'}
                    width={180}
                    visible={false}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'udt'}
                    caption={trans('Дата обновления')}
                    dataType={'datetime'}
                    format={'dd/MM/yyyy HH:mm:ss'}
                    width={180}
                    visible={false}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    caption={''}
                    width={50}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

            </DataGrid>
        </>

    );
};

export default inject('store')(observer(PayerPage));
